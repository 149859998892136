import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import rbac from 'base/modules/rbac'
import storeCart from 'base/modules/cart'
import storeUser from 'base/modules/user'
import storeTasks from 'base/modules/tasks'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCoffee,
  faBell,
  faAsterisk,
  faClock,
  faBars,
  faUserCog,
  faChevronDown,
  faChevronUp,
  faCheck,
  faBan,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPowerOff,
  faDownload,
  faInfoCircle,
  faPencilAlt,
  faCaretDown,
  faCaretUp,
  faHome,
  faArrowUp,
  faBriefcase
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as Sentry from "@sentry/vue"

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://b3464410039f4e5b89dadc8e76718a45@o248366.ingest.sentry.io/1431461',
//     integrations: [new Integrations.Vue({ Vue, attachProps: true })]
//   })
// }

// import './registerServiceWorker'
if (typeof navigator.serviceWorker !== 'undefined') {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}

import { addStat } from 'base/modules/analytics'

const objectToValuesPolyfill = (object) => {
  Object.keys(object).map(key => object[key])
}
Object.values = Object.values || objectToValuesPolyfill

library.add(faCoffee)
library.add(faBell)
library.add(faAsterisk)
library.add(faClock)
library.add(faCheck)
library.add(faBars)
library.add(faUserCog)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faInfoCircle)
library.add(faBan)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faAngleDoubleLeft)
library.add(faAngleDoubleRight)
library.add(faPowerOff)
library.add(faDownload)
library.add(faPencilAlt)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faHome)
library.add(faArrowUp)
library.add(faBriefcase)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: false,
  showMethodName: false,
  separator: '|',
  showConsoleColors: true
})

Vue.config.productionTip = false

const storeMain = {
  namespaced: true,
  state: {
    code: '',
    region: 'ru',
    pointsExchangeRate: 1,
    title: ''
  },
  mutations: {
    updateCode: function (state, code) {
      state.code = code
    },
    updateRegion: function (state, region) {
      state.region = region
    },
    updatePointsExchangeRate: function (state, pointsExchangeRate) {
      state.pointsExchangeRate = pointsExchangeRate
    },
    updateTitle: function (state, title) {
      state.title = title
    }
  },
  getters: {
    code(state) {
      return state.code
    },
    region(state) {
      return state.region
    },
    pointsExchangeRate(state) {
      return state.pointsExchangeRate
    },
    title(state) {
      return state.title
    }
  }
}

export default {
  getInstance (options) {
    // if (process.env.NODE_ENV === 'production') {
    //   Sentry.init({
    //     Vue,
    //     dsn: 'https://b3464410039f4e5b89dadc8e76718a45@o248366.ingest.sentry.io/1431461',
    //     integrations: [
    //       new Sentry.BrowserTracing({
    //         // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //         // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    //         routingInstrumentation: Sentry.vueRouterInstrumentation(options.router)
    //       })
    //     ],
      
    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for performance monitoring.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 1.0,
      
    //     // Capture Replay for 10% of all sessions,
    //     // plus for 100% of sessions with an error
    //     replaysSessionSampleRate: 0.1,
    //     replaysOnErrorSampleRate: 1.0
    //   })
    // }

    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      router: options.router,
      store: options.store,
      components: { App: options.App },
      template: '<App/>',
      watch: {
        '$route.path': 'checkPermissions'
      },
      // render: h => h(App),
      methods: {
        checkPermissions: function () {
          let fallbackPath

          try {
            fallbackPath = this.$route.meta.permissions.path || '/auth'
          } catch (error) {
            fallbackPath = '/auth'
          }

          this.$store.dispatch('user/checkAuth').then(() => {
            const role = this.$root.getUser().role || 'guest'
            if (!rbac.checkPermissions(role, this.$route)) {
              this.$router.push(fallbackPath).catch(()=>{})
            }
          }).catch((e) => {
            this.$store.dispatch('user/logout')
            this.$router.push(fallbackPath).catch(()=>{})
          })
        },
        getUser: function () {
          return this.$store.state.user
        }
      },
      created: function () {
        // Логгер
        Vue.$logger = {}
        const app = this
        Object.keys(Vue.$log).forEach((level) => {
          Vue.$logger[level] = function () {
            Vue.$log[level].apply(app, arguments)
          }
        })
        Vue.$logger.debug('Site: ' + location.protocol + '//' + location.host)
        Vue.$logger.debug('User agent: ' + navigator.userAgent)

        this.$store.registerModule('main', storeMain)
        this.$store.registerModule('user', storeUser)
        this.$store.registerModule('cart', storeCart)
        this.$store.registerModule('tasks', storeTasks)

        this.$store.commit('main/updateCode', options.code)
        this.$store.commit('main/updateRegion', options.region)
        this.$store.commit('main/updatePointsExchangeRate', options.pointsExchangeRate || 1)
        this.$store.commit('main/updateTitle', options.title)

        this.checkPermissions()

        // Analytics
        if (!location.host.includes('localhost')) {
          addStat(options.code)
        }

        this.$store.dispatch('cart/syncFromStorage')
      },
      mounted: function () {
        setInterval(() => {
          if(!this.getUser().authenticated) return
          //запрашиваем таски каждые десять секунд
          this.$store.dispatch('tasks/get').catch((e) => { console.log(e) })
        }, 10000)
      }
    })
  }
}
